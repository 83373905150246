<template>

	<div
		fill-height
		class="home"
	>
		<div
			class="mt-10 home-top"
		>
			<div
				class="text-center"
			>
				<v-img
					src="@/assets/img/logo-branch.png"
					width="104"
					style="margin: 40px auto; 0"
					class="mb-10"
				></v-img>
				
				<span 
					class="float-right pa-0 home-top-icon"
				>
					<v-btn
						x-small
						text
						:to="'/Help/Making'"
						class="pa-0 ma-0"
						min-width="0"
					>
						
						<v-badge
							color="red"
							offset-x="10"
							offset-y="10"
							:content="bell_count"
							:value="bell_count"
						><v-icon style="font-size: 1.8em;">mdi-bell-outline</v-icon></v-badge>
					</v-btn>
					<v-btn
						x-small
						text
						:to="'/mypage'"
					>
						<v-icon style="font-size: 1.8em;">mdi-cog-outline</v-icon>
					</v-btn>
				</span>
			</div>
		</div>
		
		<div
			class="contents"
				
		>
			<div
				style="position: relative;"
			>
			
				<v-overlay
					v-if="!user || user.serviceStatus != serviceStatus.success"
					absolute
					class="rounded-lg pa-5 pt-10 text-center text-subtitle-2"
					:opacity="0.85"
				>
					<v-icon 
						v-if="user.serviceStatus == serviceStatus.none"
						class="text-h4">mdi-alert-outline</v-icon>
					<v-icon 
						v-if="user.serviceStatus == serviceStatus.wait"
						class="text-h4">mdi-clipboard-check-outline</v-icon>
					
					<br/><br/>
					<div
						v-if="user.serviceStatus == serviceStatus.none"
					>
					PayLink 서비스를 이용하시려면 서비스 신청을 진행하셔야 합니다 
					</div>
					<div
						v-if="user.serviceStatus == serviceStatus.wait"
					>
					PayLink 서비스 심사중입니다
					</div>
					<br/><br/>
					<div
						v-if="user.serviceStatus == serviceStatus.none"
					>
					<router-link
						to="/SignUp"
						class="text-underline text-white"
					>
						서비스 신청하러 가기
					</router-link> <v-icon>mdi-chevron-right</v-icon>
					</div>
				</v-overlay>
				
				<div
					fill-height
					class="mt-0"
				>
					
					<v-row
						class="pa-1 ma-0"
					>
						<v-col
							class="pa-0 ma-0 text-left"
						>
							
							<div class="mt-4 text-h6 font-weight-bold">{{ user.salesManager }} 님</div>
						</v-col>
						
						<v-col
							cols="5"
							class="text-left pa-0 ma-0 d-flex align-end justify-end text-caption"
						>
						</v-col>
					</v-row>
				</div>
				
				<v-card
					class="pa-5 mt-5 rounded-lg"
					elevation="5"
				>
					<v-row
						class="pa-0 ma-0 mt-6 text-center"
					>
						<v-col
							class="pa-0 pr-2 ma-0 text-caption"
							cols="6"
						>
							<span class="d-inline-block pb-1 border-bottom border-weight-2">일일 정산금액</span>
							<span 
								class="d-block mt-3"
							><span class="text-color text-h6">{{ user.daySettlementAmount | makeComma}}</span>원</span>
						</v-col>
					
						<v-col
							class="pa-0 ma-0 pl-2 text-caption"
							cols="6"
						>
							<span class="d-inline-block pb-1 border-bottom border-weight-2">월간 정산금액</span>
							<span 
								class="d-block mt-3"
							><span class="text-color text-h6">{{ user.monthSettlementAmount | makeComma}}</span>원</span>
						</v-col>
					</v-row>
					
				</v-card>
							
				<div class="mt-5 d-box">
					<v-btn
						block
						class="pa-10 text-h6 rounded-lg text-center success2"
						:to="'/Payment/Item'"
						height="150px"
						>
						<div
							class="d-block"><v-icon large class="mr-2">mdi-credit-card-multiple-outline</v-icon></div>
						<div
							class="mt-5"
						>신용카드 수기결제</div>
					</v-btn>
				</div>
				
				<div class="mt-5 mb-10">
				
					<v-btn
						block
						class="pa-10 text-h6 rounded-lg text-center success2"
						:to="'/Payment/List'"
						>
						
						<div
						>결제 현황</div>
					</v-btn>
				</div>
				
				<div class="mt-10">
					<v-btn
						block
						outlined
						color="success2"
						class="mt-5 rounded-lg"
						
						@click="modalEvent"
					>카드사 무이자 할부
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
			this.now = new Date()
			this.$emit('setUser', localStorage.getItem('paylinkt'))
		}
		,props: ['callBack', 'user']
		,data: function(){
			return {
				now: ''
				,bell_count: 0
				,program: {
					title: '메인'
					,not_title: true
					,not_navigation: true
				}
				,serviceStatus: {
					none: 'NotApplied'
					,wait: 'Review'
					,success: 'Apply'
				}
			}
		}
		,methods: {
			modalEvent: function(){
				this.$emit('modal', {
					title: '카드사 무이자 할부 안내'
					,contents: '무이자 할부'
				})
			}
		}
		,watch: {
		}
	}
</script>

<style>
	
	.home { padding: 0 !important; margin: 0 !important; margin-bottom: 30px !important;}
	.home .home-top {height: 100px; position: relative; margin: 0 !important; overflow: hidden;}
	.home .home-top .home-top-icon {position: absolute; top: 40px; right: 0;}
	.home .home-top .home-top-icon .v-badge__badge {padding: 3px 5px 2px !important; min-width:  12px !important; height: 15px !important; font-size: 0.6em;}
	.home .logo-position {
		width: 100%;
		padding: 20px !important;
	}
	.home .contents { 
		padding: 15px !important;
		background: white; 
	}
	.home .icon-line {position: absolute; right: -2px; top: 20%; min-height: 45% !important; background: #e0e0e0 !important;}
	
	.d-box .v-btn__content {display: block !important;}
</style>


























